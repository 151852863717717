/* eslint-disable */

<template>
  <vx-card>
    <div>
      <h5 class="p-2 bg-primary rounded-t">Voter Registration</h5>
      <div class="border-ui-box pl-4 pr-4 pt-10 pb-10">
        <!--- READ RANGE BOX START --->
        <div class="flex justify-center">
          <div style="width: 50%; max-width: 650px">
            <h5 class="p-2 bg-primary rounded-t">Determine Age and Eligibility to Votes</h5>
            <div class="border-ui-box p-4">
              <div class="vx-row">
                <div class="vx-col w-5/6">
                  <vs-input class="w-full mb-2" value="NamesBirthdays.xlsx" readonly="true" />
                </div>
                <div class="vx-col w-1/6">
                  <vs-button class="w-full">...</vs-button>
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col w-3/4">
                  <vs-input class="w-full mt-2" value="“Personal Info”" readonly="true" />
                </div>
                <div class="vx-col w-1/4">
                  <vs-input class="w-full mt-2" value="“”" readonly="true" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--- READ RANGE BOX END --->

        <!--- ARROW DOWN START --->
        <div class="flex justify-center my-6">
          <feather-icon icon="ArrowDownIcon" svgClasses="w-16 h-16"></feather-icon>
        </div>
        <!--- ARROW DOWN END --->

        <!--- DETERMINE AGE BOX START --->
        <div class="flex justify-center">
          <div style="width: 90%">
            <h5 class="p-2 bg-primary rounded-t">Determine Age and Eligibility to Votes</h5>
            <div class="border-ui-box pl-4 pr-4 pt-10 pb-10 flex justify-center">
              <!--- FOR EACH ROW NAMES & BIRTHDAYS START --->
              <div style="width: 90%">
                <h5 class="p-2 bg-primary rounded-t">For Each Row – Names and Birthdays</h5>
                <div class="border-ui-box p-4">
                  <div class="flex">
                    <h3 class="self-center mr-2">ForEach</h3>
                    <vs-input class="ml-2 mr-2" value="row" readonly="true" />
                    <h3 class="self-center ml-2 mr-2">in</h3>
                    <vs-input class="ml-2" value="DTNamesAndBirthdays" readonly="true" />
                  </div>
                  <h3 class="mt-4 mb-2">Body</h3>
                  <div class="border-ui-box pl-4 pr-4 pt-4 pb-4">
                    <!--- BODY OF FOR EACH ROW START --->
                    <div style="width: 100%">
                      <h5 class="p-2 bg-primary rounded-t">Body of For Each Row</h5>
                      <div class="border-ui-box pl-4 pr-4 pt-10 pb-10">
                        <!-- ASSIGN DOB START --->
                        <div class="w-full flex justify-center">
                          <div style="width: 50%; max-width: 650px">
                            <h5 class="p-2 bg-primary rounded-t">Assign - Date minus DOB</h5>
                            <div class="border-ui-box p-4">
                              <div class="flex">
                                <vs-input class="w-full mb-2" value="TimeOnThePlanet" readonly="true" />
                                <h2 class="self-center ml-2 mr-2">=</h2>
                                <vs-input class="w-full mb-2" value="Date.Now.Date - C" readonly="true" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- ASSIGN DOB END --->

                        <!--- ARROW DOWN START --->
                        <div class="flex justify-center my-6">
                          <feather-icon icon="ArrowDownIcon" svgClasses="w-16 h-16"></feather-icon>
                        </div>
                        <!--- ARROW DOWN END --->

                        <!-- ASSIGN AGE START --->
                        <div class="w-full flex justify-center">
                          <div style="width: 50%; max-width: 650px">
                            <h5 class="p-2 bg-primary rounded-t">Assign - Date minus DOB</h5>
                            <div class="border-ui-box p-4">
                              <div class="flex">
                                <vs-input class="w-full mb-2" value="Age" readonly="true" />
                                <h2 class="self-center ml-2 mr-2">=</h2>
                                <vs-input class="w-full mb-2" value="TimeOnPlanet." readonly="true" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- ASSIGN AGE END --->

                        <!--- ARROW DOWN START --->
                        <div class="flex justify-center my-6">
                          <feather-icon icon="ArrowDownIcon" svgClasses="w-16 h-16"></feather-icon>
                        </div>
                        <!--- ARROW DOWN END --->

                        <!--- If AGE IS ABOVE LEGAL START --->
                        <div style="width: 100%">
                          <h5 class="p-2 bg-primary rounded-t">If - Age is greater than or equal to 18</h5>
                          <div class="border-ui-box pl-4 pr-4 pt-4 pb-4">
                            <vs-input class="mt-6 w-4/5" value="Age >= 18" label-placeholder="Condition" readonly="true" />

                            <div class="vx-row mt-2">
                              <!-- CAN VOTE START --->
                              <div class="vx-col w-1/2">
                                <h1 class="text-center my-3">Then</h1>
                                <div class="w-full flex justify-center">
                                  <div style="width: 100%">
                                    <h5 class="p-2 bg-primary rounded-t">Sequence - Yes, Can Vote</h5>
                                    <div class="border-ui-box p-6">
                                      <!-- LOG MESSAGE START --->
                                      <div class="w-full flex justify-center">
                                        <div style="width: 100%">
                                          <h5 class="p-2 bg-primary rounded-t">Log Message - Can Register To Vote</h5>
                                          <div class="border-ui-box p-4">
                                            <div class="flex mb-2">
                                              <h2 class="self-center mr-2">Log Level</h2>
                                              <vs-input class="ml-2" value="info" readonly="true" />
                                            </div>
                                            <div class="flex mt-2">
                                              <h2 class="self-center mr-2">Message</h2>
                                              <vs-input
                                                class="ml-2 w-full"
                                                value="Row(“Name”).ToString + “is eligible to vote"
                                                readonly="true"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <!-- LOG MESSAGE END --->
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- CAN VOTE END --->

                              <!-- CAN NOT VOTE START --->
                              <div class="vx-col w-1/2">
                                <h1 class="text-center my-3">Else</h1>
                                <div class="w-full flex justify-center">
                                  <div style="width: 100%">
                                    <h5 class="p-2 bg-primary rounded-t">Sequence - No, Cannot Vote</h5>
                                    <div class="border-ui-box p-6">
                                      <!-- LOG MESSAGE START --->
                                      <div class="w-full flex justify-center">
                                        <div style="width: 100%">
                                          <h5 class="p-2 bg-primary rounded-t">Log Message - Can Register To Vote</h5>
                                          <div class="border-ui-box p-4">
                                            <div class="flex mb-2">
                                              <h2 class="self-center mr-2">Log Level</h2>
                                              <vs-input class="ml-2" value="info" readonly="true" />
                                            </div>
                                            <div class="flex mt-2">
                                              <h2 class="self-center mr-2">Message</h2>
                                              <vs-input
                                                class="ml-2 w-full"
                                                value="Row(“Name”).To String + “is not eligible to vote"
                                                readonly="true"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <!-- LOG MESSAGE END --->
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- CAN NOT VOTE END --->
                            </div>
                          </div>
                        </div>
                        <!--- If AGE IS ABOVE LEGAL END --->
                      </div>
                    </div>
                    <!--- BODY OF FOR EACH ROW END --->
                  </div>
                </div>
              </div>
              <!--- FOR EACH ROW NAMES & BIRTHDAYS END --->
            </div>
          </div>
        </div>
        <!--- DETERMINE AGE BOX END --->
      </div>
    </div>
  </vx-card>
</template>

<script>
import Prism from 'vue-prism-component';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),

      selector_edits: [
        { selected: false, name: '&lt; htmlapp=&#39;msedge.exe&#39;title=&#39;Rpa Challenge&#39;/&gt;' },
        { selected: false, name: '&lt;webctrl css-selector=&#39;body&#39;tag=&#39;BODY&#39;/&gt;' },
        { selected: false, name: '&lt;webctrl css-selector=&#39;body&amp;gtapp-root&#39;tag=APP-RO' },
        { selected: false, name: '&lt;webctrl css-selector=&#39;body&amp;gtapp-root&amp;gt.div&#39;tag-' },
        { selected: false, name: '&lt;webctrl css-selector=&#39;body&amp;gtapp-root&amp;gt.div&amp;gt.ap' },
        { selected: false, name: '&lt;webctrl css-selector=&#39;body&amp;gtapp-root&amp;gt.div&amp;gt.ap' },
        { selected: false, name: '&lt;webctrl css-selector=&#39;body&amp;gtapp-root&amp;gt.div&amp;gt.ap' },
        { selected: false, name: '&lt;webctrl css-selector=&#39;body&amp;gtapp-root&amp;gt.div&amp;gt.ap' },
        { selected: false, name: '&lt;webctrl css-selector=&#39;body&amp;gtapp-root&amp;gt.div&amp;gt.ap' },
        { selected: false, name: '&lt;webctrl css-selector=&#39;body&amp;gtapp-root&amp;gt.div&amp;gt.ap' },
        { selected: false, name: '&lt;webctrl css-selector=&#39;body&amp;gtapp-root&amp;gt.div&amp;gt.ap' },
        { selected: false, name: '&lt;webctrl css-selector=&#39;body&amp;gtapp-root&amp;gt.div&amp;gt.ap' },
        { selected: false, name: '&lt;webctrl aaname=&#39;First Name&#39; tag=&#39;LABEL&#39;/&gt;' },
        { selected: false, name: '&lt;nav up=&#39;1’? /&gt;' },
        { selected: false, name: '&lt;webctrl class=&#39;ng-untouched ng-pristine ng-invalid&#39; pa' },
      ],

      unselected_items: [
        'class',
        'ng-untouched',
        'css-selector',
        'body',
        'id',
        'choXw',
        'isleaf',
        '1',
        'name',
        'choXw',
        'parentclass',
        'ng-untouched',
        'tag',
        'INPUT',
        'title',
        'Rpa Challenge',
      ],
    };
  },
  methods: {
    markSimulation() {
      const totalScore = 0;

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  created() {},
  components: {
    Prism,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style scoped>
.border-ui-box {
  border-width: 2px 2px 2px 2px;
  border-color: white;
  border-style: solid;
  border-radius: 0 0 2px 2px;
}
</style>
